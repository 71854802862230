var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"a23f5b478d7eef152412fa5ebc8d85bece82efca"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { Errors } from "./types/api";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
    dsn: SENTRY_DSN,
    enabled: process.env.NODE_ENV === "production",
    environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
    attachStacktrace: true,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    denyUrls: [
        // Google Adsense
        /pagead\/js/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i
    ],
    ignoreErrors: [
        "ResizeObserver loop limit exceeded",
        "Non-Error promise rejection captured with value: Abort route change. Please ignore this error.",
        "ResizeObserver loop completed with undelivered notifications.",
        "TypeError: Failed to fetch",
        "NetworkError: Load failed",
        "Uncaught NetworkError: Failed to execute 'importScripts' on 'WorkerGlobalScope':",
        "null is not an object (evaluating 'a[w.checkoutSettingKeys.devToolsEnabled]')",
        "TypeError: NetworkError when attempting to fetch resource.",
        "Non-Error promise rejection captured with value: Object Not Found Matching Id",
        "NotSupportedError: Failed to get ServiceWorkerRegistration objects: The user denied permission to use Service Worker.",
        "cancelled",
        "dailymotion is not defined",
        "jwDefaults is not defined",
        "YT is not defined",
        "IMUID is not defined",
        "moat_px is not defined",
        "googlefc is not defined",
        "apstagLOADED is not defined",
        "__cmp is not defined",
        "_reportEvent is not defined",
        "Event `CustomEvent` (type=unhandledrejection) captured as promise rejection",
        "ezDenty is not defined",
        "NotAllowedError: Write permission denied.",
        "TypeError: Load failed",
        "Cannot read properties of undefined",
        "InvalidStateError: Failed to get ServiceWorkerRegistration objects",
        "Non-Error promise rejection captured with value: Not implemented on this platform",
        "It looks like your website URL has been changed.",
        "null is not an object (evaluating 'a[C.checkoutSettingKeys.devToolsEnabled]')",
        "Failed to load Stripe.js",
        "Clipboard write was blocked due to lack of user activation"
    ],
    beforeSend: (event, hint) => {
        if (!hint || !hint.originalException) return event;

        const error = hint.originalException;
        const url = error?.config?.url;
        const isApp = window.location.pathname.startsWith("/app");

        // Ignore benign error: https://github.com/getsentry/sentry-javascript/issues/3440
        if (
            hint &&
            hint.originalException &&
            hint.originalException.message &&
            typeof hint.originalException.message === "string" &&
            hint.originalException.message.includes("Non-Error promise rejection captured with")
        )
            return null;

        // Only report app pages
        if (!isApp) return null;

        // Ignore some axios errors
        if (error?.isAxiosError) {
            const isForbidden = error?.response?.status === Errors.AccessDenied;
            const isInvalid = error?.response?.status === Errors.State;
            const isValidation = error?.response?.status === Errors.Validation;
            const isSignIn = typeof url === "string" && url.endsWith("/signin");

            // Dont report 409, UI should handle those fine
            if (isInvalid || isValidation) {
                return null;
            }

            // Dont report 403 for signin on axios
            if (isForbidden && isSignIn) {
                return null;
            }
        }

        // Ignore google sign in not starting (i.e. incognito blocking 3rd party cookies)
        const details = error?.details;

        if (typeof details === "string") {
            if (details === "Cookies are not enabled in current environment.") {
                return null;
            }
        }

        return event;
    }
});
