export enum NotificationTargets {
    SEO = "SEO",
    ProfileShareChange = "ProfileShareChange",
    ProfileFilterChange = "ProfileFilterChange",
    AddCollectionToProfile = "AddCollectionToProfile",
    RemoveCollectionFromProfile = "RemoveCollectionFromProfile",
    CollectionOrderProfile = "CollectionOrderProfile",
    ProfileBioUpdated = "ProfileBioUpdated",
    ProfileCoverUpdated = "ProfileCoverUpdated",
    ProfileLayoutUpdated = "ProfileLayoutUpdated",
    ProfileContentLayoutUpdated = "ProfileContentLayoutUpdated",
    ProfileSocialMediaLinks = "ProfileSocialMediaLinks",
    ProfileHighlightButton = "ProfileHighlightButton",
    ProfileEmailSubscribeEnabled = "ProfileEmailSubscribeEnabled",
    ProfileEmailSubscribeDisabled = "ProfileEmailSubscribeDisabled",
    ContentLinkSettingsUpdated = "ContentLinkSettingsUpdated",
    CollectionInfoUpdated = "CollectionInfoUpdated",
    CollectionCoverUpdated = "CollectionCoverUpdated",
    CollectionNameUpdated = "CollectionNameUpdated",
    CollectionLayoutUpdated = "CollectionLayoutUpdated",
    CollectionLinkSettingsUpdated = "CollectionLinkSettingsUpdated",
    CollectionItemOrderUpdated = "CollectionItemOrderUpdated",
    NewsletterScheduleUpdated = "NewsletterScheduleUpdated",
    NewsletterInfoUpdated = "NewsletterInfoUpdated",
    NewsletterForwardRepliesUpdated = "NewsletterForwardRepliesUpdated",
    UserGDPRSettingsUpdated = "UserGDPRSettingsUpdated",
    UserGDPRPolicyUpdated = "UserGDPRPolicyUpdated",
    UserGDPRImprintUpdated = "UserGDPRImprintUpdated",
    UserNameUpdated = "UserNameUpdated",
    UserAliasUpdated = "UserAliasUpdated",
    UserEmailUpdated = "UserEmailUpdated",
    UserPasswordUpdated = "UserPasswordUpdated",
    UserPlanUpdated = "UserPlanUpdated",
    UserPaymentUpdated = "UserPaymentUpdated",
    RemoveFavorite = "RemoveFavorite",
    MarkAsFavorite = "MarkAsFavorite",
    ContentEdited = "ContentEdited",
    AboutPageRenamed = "AboutPageRenamed",
    SearchEngineSettingsUpdated = "SearchEngineSettingsUpdated"
}
